.footer{
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding-left: 5px;
  text-align: left;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 8rem;
}

.footer>div{
  margin: 5px;
}

.footer>div>a{
  color:rgba(255, 255, 255, 1.0);
}

.footer>div>a:hover{
  color:rgba(255, 255, 255, 1.0);
}
.navbar-brand {
  display: flex;
  align-items: center;
  float: left;
}

.navbar-brand>img {
  display: inline-block;
  vertical-align: top;
  max-height: 56px;
  padding-top: .125rem;
  padding-bottom: .25rem;
}

h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  margin: 6px 0 6px 10px;
  display: inline-block;
  vertical-align: top;
}

.nav-link{
  color:rgba(255, 255, 255, 1.0) !important;
}

.menu-right{
  margin-right: auto !important;
}

button + button {
  margin-left: 10px;
}

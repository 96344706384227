.main-content{
  margin: 1%;
  padding-bottom: 9rem;
  max-width: 1375px;
}

@media (min-width:1375px){
  .main-content {
    padding-left: calc((50% - 1375px)/2)
  }
 }

.page-content{
  position: relative;
  min-height: 100vh;
}
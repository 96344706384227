.bio-cell {
  margin-bottom: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

.bio-cell-image {
  min-height: 500px;
  margin-bottom: 2vw;
  padding-left: 2vw;
  padding-right: 2vw;
}

.bio-no-image {
  min-height: 100px
}

.bio-header {
  width: 85%;
}

.bio-center-header {
  width: 85%;
}

.bio-center-content {
  text-align: center;
}

.empty {
  display: flex;
  flex-grow: 1;
  width: 15%;
}

.empty::after {
  content: '.';
  visibility: hidden;
}

.bio-image {
  float: left;
}

.bio > img {
  max-width: 100%;
  max-height: 400px;
}

@media screen and (min-width: 1024px) {
  .bio-image {
    float: left;
    min-width: 33.3%
  }

  .bio-image > img {
    max-width: 30%;
    max-height: 400px;
  }
}
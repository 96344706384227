.gsf-card-body {
  min-height: 1px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.card-text > p > a {
  color: #3e80c2;
  font-style: italic;
}

.card-text > p > a:hover {
  color: #aec4da;
  font-style: italic;
};

.gsf-card-footer {
  display: flex;
  flex-grow: 1;
}

.vert-card-footer {
  bottom: 0;
  padding-top: 10px;
}
.card {
  width: 100%;
  max-width: 650px;
}

.demoCard{
  max-width: 500px;
}

.gsf-card{
  padding-top: 10px;
  padding-bottom: 10px;
}

.no-bottom-padding{
  padding-bottom: 0px;
}

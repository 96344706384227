.carousel-container {
  display: flex;
  justify-content: center;
}

.carousel {
  width: 90vw;
  max-height: 524px;
  align-items: flex-end;
  display: flex;
  max-width: 1375px;
}

.carousel>img {
  width: 90vw;
  overflow: hidden;
}